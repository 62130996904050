#rules {

    text-align: center;


    .rule-book-pdf{
        text-decoration: underline;
        @media(max-width: 850px){
            margin-top: 10rem;
        }
    }

  .rule-book-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    width: 100%;

    @media(max-width: 850px){
        display: none;
    }

    .rule-book {
      display: flex;
      max-width: 900px;
      justify-content: center;

      .rule-page {
        width: 450px;
        border: 1px solid $color-white;
      }
    }
  }
}
