body {
  background-image: url("../images/shape-background.png");
  background-color: $color-background;
  background-position: 50% -120px; /* Center the image */
  background-repeat: no-repeat, repeat;
  color: $color-black;
  transition: background-color 1s ease;
  transition: color 0.5s ease;
  font-family: $font-text;

  @media (max-width: 500px) {
    background-image: none;
  }
}

h1 {
  font-family: $font-title;
  font-weight: 450;
  letter-spacing: 0.1rem;
  font-size: 6rem;
  line-height: 1.2;
  color: $color-text;
  margin: 40px;
  letter-spacing: 5px;
}

h2 {
  font-family: $font-headers;
  font-size: 2.5rem;
  color: $color-text;
}

h3 {
  font-family: $font-text;
  font-size: 2.3rem;
  color: $color-white;
}

h4 {
  font-family: $font-text;
  font-weight: bold;
  font-size: 1.5rem;
}

p {
  font-family: $font-text;
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: inherit;
  font-family: $font-headers;
  font-size: 2.5rem;
  color: $color-text;
  font-weight: 700;

  &:hover {
    color: $color-gold;
  }
}

button {
  font-size: 3.5rem;
  background-color: $color-yellow;
  z-index: 5;
  margin: -15px;
  padding: 1rem;
  font-weight: bold;
  color: $color-white;
  box-shadow: inset;
  border-radius: 7px;
  cursor: pointer;

  // &:hover {
  //   opacity: 80%;
  //   box-shadow: 1px 1px 1px 1px rgba(25, 25, 0, 0.4);
  // }
}



.pnp{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $color-white;
    max-width: 730px;
    margin: 10rem auto;
  p{
    font-size: 2rem;
  }

}


.msg {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: $color-white;
  max-width: 730px;
  margin: 10rem auto;

  .shape{
    width: 100px;
    margin: 0 0 -85px;
  }

  h3{
    margin: 2rem 0 3rem;
  }

  p{
    margin-bottom: 1.2rem;
    font-size: 1.6rem;
    line-height: 1.3;
  }

  div{
    margin: 2rem;
  }

  #general-inquiries{
    color: $color-white;
  }

  #creative-team{
    color: $color-white;
  }

  a{
    font-size: 1.6rem;
    text-decoration: underline;
  }
}
