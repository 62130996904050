#links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  a {
    max-width: 300px;
    margin: 20px;
    padding: 20px;
    text-align: center;
    background-color: $color-red;
    border-radius: 5px;

    background-color: $color-gold;
    color: $color-dark-blue;

    &:hover {
        background-color: $color-green;
        color: $color-white;
    }
  }
}

.formation-logo {
  max-width: 300px;
  width: 80%;
  margin: 25px;
}
