* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    max-width: 100%;
   
  }
  
  html {
    font-size: 62.5%;
  }
  
  button {
    &:focus {
      outline: none;
    }
    border: none;
  }