header {

    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 768px;

    @media(max-width:500px){
        flex-direction: column;
        
    }

    .formation-logo{
        
        width:125px;
        margin: 20px;
    }

    
    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 auto;
      width: 80%;
      max-height: 125px;

        @media(max-width: 758px){
            flex-direction: column;
            justify-content: space-evenly;
        }

        @media(max-width: 500px){
            flex-direction: row;
            width: 100%;
            max-height: none;
            border-top: 1px solid $color-white;
            border-bottom: 1px solid $color-white;
        }

      a {
        margin: 1rem;

        @media(max-width: 500px){
            font-size: 2rem;
            width: 45%;
            margin: none;
        }
      }
    }
  }